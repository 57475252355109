export const usersStartingCredits = 1;

export const songCreditCost = 1;

export const tempoOptions = [
  "Slow tempo (40-80 BPM)",
  "Medium tempo (80-120 BPM)",
  "Fast tempo (120-160 BPM)",
  "Variable Tempo (varying BPM)",
  "Increasing tempo (varying BPM)",
  "Decreasing Tempo (varying BPM)",
];

export const genreOptions = [
  "Alternative",
  "Classical",
  "Country",
  "Dance",
  "Dancehall",
  "Disco",
  "Dubstep",
  "EDM",
  "Electro",
  "Emo",
  "Folk",
  "Funk",
  "Gospel",
  "HipHop",
  "House",
  "Indie",
  "Jazz",
  "Kpop",
  "Lowfi",
  "Rap",
  "Rnb",
  "Rock",
  "Soul",
  "Synthwave",
  "Trance",
  "Trap",
];

export const moodOptions = [
  "Aggressive",
  "Ambient",
  "Artsy",
  "Atmospheric",
  "Bedroom",
  "Bittersweet",
  "Celebratory",
  "Cinematic",
  "Cold",
  "Danceable",
  "Dark",
  "Dramatic",
  "Emotional",
  "Ethereal",
  "Festive",
  "Garage",
  "Groovy",
  "Happy",
  "Lounge",
  "Lullaby",
  "Magical",
  "Melancholic",
  "Melodramatic",
  "Minimal",
  "Mysterious",
  "Nostalgic",
  "Party",
  "Peaceful",
  "Power",
  "Quirky",
  "Relaxed",
  "Sad",
  "Sinister",
  "Soft",
  "Suspenseful",
  "Thrilling",
  "Villain",
  "Vulnerable",
  "Warm",
];

export const voiceOptions = ["Male", "Female"];
