import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

// Function to increment user credits by a specified amount (default is 1)
async function incrementUserCredits(userEmail, incrementBy = 1) {
  try {
    // Query to find the user document by email
    const q = query(collection(db, "users"), where("email", "==", userEmail));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      // Get the first document found (assuming email is unique)
      const userDoc = querySnapshot.docs[0];
      const userDocRef = doc(db, "users", userDoc.id);

      // Increment the credits field
      await updateDoc(userDocRef, {
        credits: userDoc.data().credits + incrementBy,
      });

      console.log("Credits incremented successfully");
    } else {
      console.log("No user found with the given email");
    }
  } catch (error) {
    console.error("Error incrementing credits: ", error);
  }
}

export default incrementUserCredits;
