import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    console.log("tring to login");
    event.preventDefault();
    setError("");
    try {
      console.log(email);
      console.log(password);
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setError(error.message);
      console.log(error);
    }
    navigate("/");
  };

  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-regal-blue">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl/9 font-bold tracking-tight text-regal-beige">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block text-sm/6 font-medium text-regal-beige"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="email"
                className="block w-full bg-regal-beige rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-regal-beige focus:ring-2 focus:ring-inset focus:ring-focus-ring-color sm:text-sm/6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm/6 font-medium text-regal-beige"
              >
                Password
              </label>
              <div className="text-sm">
                <Link
                  to="../forgotPassword"
                  className="font-semibold text-regal-beige hover:text-focus-ring-color"
                >
                  Forgot password?
                </Link>
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                autoComplete="current-password"
                className="block w-full rounded-md border-0 py-1.5 text-black bg-regal-beige shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-regal-beige focus:ring-2 focus:ring-inset focus:ring-focus-ring-color sm:text-sm/6"
              />
            </div>
          </div>

          {error && <div className="text-red-600 text-sm">{error}</div>}

          <div>
            <button
              type="submit"
              className="relative w-full inline-flex items-center justify-center outline-regal-beige outline outline-2 rounded-md bg-regal-blue px-3 py-2 text-sm font-semibold text-regal-beige shadow-sm "
            >
              Sign in
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm/6 text-regal-beige">
          Not a member?{" "}
          <Link
            to="../createAccount"
            className="font-semibold text-indigo-600 hover:text-indigo-500"
          >
            Join Now
          </Link>
        </p>
      </div>
    </div>
  );
}
