import React from "react";
import { Link } from "react-router-dom";

export default function SignedOut() {
  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-regal-blue">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl/9 font-bold tracking-tight text-regal-beige">
          You have successfully signed out
        </h2>
        <p className="mt-4 text-center text-lg font-medium text-regal-beige">
          Hope you make another song soon!
        </p>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div>
          <Link
            to="/"
            className="relative w-full inline-flex items-center justify-center outline-regal-beige outline outline-2 rounded-md bg-regal-blue px-3 py-2 text-sm font-semibold text-regal-beige shadow-sm"
          >
            Return Home
          </Link>
        </div>
      </div>
    </div>
  );
}
