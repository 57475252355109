export default function About() {
  return (
    <div className="bg-regal-blue px-6 py-24 sm:py-32 lg:px-8 min-h-screen">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="mt-2 text-5xl font-semibold tracking-tight text-regal-beige sm:text-7xl">
          About Us
        </h2>
        <p className="mt-2 text-lg font-medium text-regal-beige sm:text-xl/8">
          we hated studying but loved listening to songs (like a human being
          does)
        </p>
        <p className="mt-2 text-lg font-medium text-regal-beige sm:text-xl/8">
          & et voila text to tracks was born
        </p>
        <p className="mt-2 text-lg font-medium text-regal-beige sm:text-xl/8">
          transforming your boring study material into lovely little songs.
        </p>
        <p className="mt-2 text-lg font-medium text-regal-beige sm:text-xl/8">
          To make your learning as comfortable and fun as possible ❤️
        </p>
        <p className="mt-2 text-lg font-medium text-regal-beige sm:text-xl/8">
          whether you are at home, on the train, walking your dog or wherever :)
        </p>
        <p className="mt-2 text-lg font-medium text-regal-beige sm:text-xl/8">
          ohhh, & we are Juan & Sam or Sam & Juan
        </p>
        <p className="mt-2 text-lg font-medium text-regal-beige sm:text-xl/8">
          & we are always welcoming feedback or recommendations.
        </p>
        <p className="mt-2 text-lg font-medium text-regal-beige sm:text-xl/8">
          So if you've got anything, just{" "}
          <a
            href="mailto:testmail@textToTracks"
            className="underline text-regal-beige hover:text-regal-beige-dark"
          >
            write us a message
          </a>{" "}
          :)
        </p>
      </div>
    </div>
  );
}
