import "./App.css";
import CTA from "./Pages/Cta";

function App() {
  return (
    <>
      <CTA />
    </>
  );
}
export default App;
