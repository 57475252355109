import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { Noise } from "noisejs";

const VisualizerDummy = () => {
  const mountRef = useRef(null);
  const rectanglesRef = useRef([]);
  const noise = new Noise(Math.random());

  useEffect(() => {
    // Set up the scene, camera, and renderer
    const scene = new THREE.Scene();
    const camera = new THREE.OrthographicCamera(
      mountRef.current.clientWidth / -2,
      mountRef.current.clientWidth / 2,
      mountRef.current.clientHeight / 2,
      mountRef.current.clientHeight / -2,
      0.1,
      1000
    );
    const currentMountRef = mountRef.current;
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(
      mountRef.current.clientWidth,
      mountRef.current.clientHeight
    );
    renderer.setClearColor(0x151e3f); // Set background color to match the provided image
    mountRef.current.appendChild(renderer.domElement);

    // Define rectangle parameters
    const rectangleCount = 40;
    const maxHeight = mountRef.current.clientHeight;
    const width = 10;
    const spacing = 5;

    // Initial x position to start from the left side of the canvas
    const initialX = -(rectangleCount * (width + spacing)) / 2;

    for (let i = 0; i < rectangleCount; i++) {
      const height = Math.random() * maxHeight;
      const shape = new THREE.Shape();
      shape.moveTo(0, 0);
      shape.lineTo(width, 0);
      shape.lineTo(width, height);
      shape.lineTo(0, height);
      shape.lineTo(0, 0);

      const points = shape.getPoints();
      const geometry = new THREE.BufferGeometry().setFromPoints(points);

      const material = new THREE.LineBasicMaterial({ color: 0xffffff });
      const line = new THREE.Line(geometry, material);

      line.position.x = initialX + i * (width + spacing);
      line.position.y = -mountRef.current.clientHeight / 2; // Align the bottom of all rectangles at y = -half height of the canvas

      scene.add(line);
      rectanglesRef.current.push({ line, initialHeight: height });
    }

    // Set the camera position
    camera.position.z = 100; // Move the camera back to see the entire scene

    // Animation loop
    let lastUpdateTime = 0;
    const updateInterval = 0.001; // Update interval in milliseconds

    const animate = (time) => {
      if (time - lastUpdateTime > updateInterval) {
        const noiseTime = time * 0.001; // Scale time for noise function

        rectanglesRef.current.forEach((rectObj, index) => {
          const { line } = rectObj;
          const noiseValue = noise.simplex2(index * 0.1, noiseTime);
          const newHeight = ((noiseValue + 1) / 2) * maxHeight; // Normalize noise value and scale to maxHeight

          const points = [
            new THREE.Vector3(0, 0, 0),
            new THREE.Vector3(width, 0, 0),
            new THREE.Vector3(width, newHeight, 0),
            new THREE.Vector3(0, newHeight, 0),
            new THREE.Vector3(0, 0, 0),
          ];
          line.geometry.setFromPoints(points);
        });

        lastUpdateTime = time;
      }

      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    };
    animate();

    // Clean up on unmount
    return () => {
      if (currentMountRef && renderer) {
        currentMountRef.removeChild(renderer.domElement);
      }
    };
  });

  return <div ref={mountRef} className="w-full h-48" />;
};

export default VisualizerDummy;
