import { db } from "../firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import axios from "axios";

export const makeSongRequest = async (docInfo) => {
  const lyrics = await rewriteWithAI(docInfo.lyrics);
  docInfo.lyrics = lyrics;
  console.log(lyrics);

  const serverTime = Timestamp.now();
  docInfo["createdAt"] = serverTime;
  docInfo["filled"] = false;
  console.log("making request info is", docInfo);

  const collectionRef = collection(db, `songRequests`);
  // return promise
  return addDoc(collectionRef, docInfo);
};

async function rewriteWithAI(lyrics, genres = []) {
  const requestBody = {
    message: lyrics,
  };

  try {
    const res = await axios.post(
      "https://gptsinglesong-y4zrrpqboq-uc.a.run.app",
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res.data.response;
  } catch (error) {
    console.error("Error:", error);
  }
}
