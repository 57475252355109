import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebaseConfig"; // Adjust the import path as needed
import SongsList from "../Components/SongsList";
import Success from "../Components/Success";

export default function SuccessPage() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser); // If the user is logged in, store user info
      } else {
        setUser(null); // If the user is logged out, set user to null
      }
    });

    // Cleanup on component unmount
    return () => unsubscribe();
  }, []);

  return (
    <>
      {user ? (
        // If the user is logged in, render the SongsList component
        <SongsList />
      ) : (
        // If the user is not logged in, render the "Create Account" section
        <Success></Success>
      )}
    </>
  );
}
