import { uploadScript } from "../Services/uploadScript";
import CustomizeSongStyle from "../Components/CustomizeSongStyle";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { validateUserEmailForSong } from "../Services/validateUserEmailForSong";

export default function UploadScript() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const defaultText = "copy/paste your study materials here... ❤️";

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setEmail(user.email);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleCustomSongData = (isValid) => {
    setIsSubmitEnabled(isValid); // Enable the submit button based on the child's state
    console.log(isSubmitEnabled);
  };

  const handleFocus = (event) => {
    console.log("handling focus");
    if (event.target.value === defaultText) {
      event.target.value = "";
    }
  };

  async function sendSubmission(event) {
    event.preventDefault(); // Prevent page refresh on form submission
    const formData = new FormData(event.target);
    const email = formData.get("email");
    const script = formData.get("script");
    const name = formData.get("name");

    const valid = await validateUserEmailForSong(email); //validate that user has credits

    if (valid) {
      localStorage.setItem("email", email); // Save email to local storage
      await uploadScript({ email: email, script: script, name: name });
      navigate("success");
    } else {
      console.log("no credits");
    }
  }
  return (
    <div class="flex px-6 py-2 pb-6  min-h-screen justify-center bg-regal-blue">
      <div className="w-full sm:w-4/5">
        <form onSubmit={sendSubmission}>
          <div className="space-y-6">
            <div className="border-b border-gray-900/10 pb-6">
              <h2 className="text-base font-semibold leading-7 text-regal-beige">
                Upload your entire script
              </h2>
              <p className="mt-1 text-sm leading-6 text-regal-beige">
                We will split your script into MANY separate songs. As soon as
                they are done, we'll mail them to you! :)
              </p>
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-regal-beige"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="block w-full bg-regal-beige rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-focus-ring-color sm:text-sm sm:leading-6 mt-5"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-regal-beige"
                  >
                    Script Name
                  </label>
                  <div className="mt-2">
                    <input
                      id="name"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="block w-full bg-regal-beige rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-focus-ring-color sm:text-sm sm:leading-6 mt-5"
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium leading-6 text-regal-beige"
                  >
                    Study Script
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="script"
                      name="script"
                      rows={10}
                      onFocus={handleFocus}
                      className="block w-full bg-regal-beige rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-focus-ring-color sm:text-sm sm:leading-6 mt-5"
                      defaultValue={defaultText}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Link to=".."></Link>
          {isSubmitEnabled && (
            <button
              type="submit"
              className=" rounded-md w-full bg-regal-blue outline-regal-beige outline outline-2 px-3 py-2 text-sm font-semibold text-regal-beige shadow-sm hover:bg-regal-beige hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-focus-ring-color "
            >
              Create Songs 🎵
            </button>
          )}
          <CustomizeSongStyle
            onValidationChange={handleCustomSongData}
          ></CustomizeSongStyle>
        </form>
      </div>
    </div>
  );
}
