import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";

export const validateUserEmailForSong = async (userEmail) => {
  console.log("checking if email has credits ");
  try {
    const q = query(collection(db, "users"), where("email", "==", userEmail));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      const credits = userDoc.data().credits;
      return credits > 0;
    } else {
      //check if user has already made a song
      const q = query(
        collection(db, "songRequests"),
        where("email", "==", userEmail)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        console.log("email hasn't made a song");
        return true;
      } else {
        console.log("user email has made song but has no account ");
        return false;
      }
    }
  } catch (error) {
    console.log(error.message);
  } finally {
    console.log("finished");
  }
};
