import React, { useEffect, useState } from "react";
import {
  Disclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../firebaseConfig";
import { onAuthStateChanged, signOut } from "firebase/auth";

export default function Header() {
  const [user, setUser] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSignOut = async () => {
    try {
      console.log("signing out");
      await signOut(auth);
      setUser(null);
      navigate("/signedOut");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <Disclosure as="nav" className="bg-regal-blue shadow">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 justify-between items-center">
          <div className="flex items-center">
            <div className="flex-shrink-0 md:ml-6">
              {location.pathname === "/uploadScript" ? (
                <Link
                  to=""
                  className="min-w-40 rounded-md bg-regal-blue outline-regal-beige outline outline-2 px-3 py-2 text-sm font-semibold text-regal-beige shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center justify-center"
                >
                  <PlusIcon
                    aria-hidden="true"
                    className="-ml-0.5 h-5 w-5 text-regal-beige mr-2"
                  />
                  Create Song 🎵
                </Link>
              ) : (
                <Link
                  to="uploadScript"
                  className="min-w-40 rounded-md bg-regal-blue outline-regal-beige outline outline-2 px-3 py-2 text-sm font-semibold text-regal-beige shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center justify-center"
                >
                  <PlusIcon
                    aria-hidden="true"
                    className="-ml-0.5 h-5 w-5 text-regal-beige mr-2"
                  />
                  Upload Script 🎵
                </Link>
              )}
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <div className="flex-shrink-0">
              <Link
                to="about"
                className="relative inline-flex items-center outline-regal-beige outline outline-2 rounded-md bg-regal-blue px-3 py-2 text-sm font-semibold text-regal-beige shadow-sm"
              >
                About
              </Link>
            </div>
            {user ? (
              <div className="flex-shrink-0">
                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <div>
                    <MenuButton className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <img
                        alt=""
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        className="h-8 w-8 rounded-full"
                      />
                    </MenuButton>
                  </div>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <MenuItem>
                      <Link
                        onClick={handleSignOut}
                        to="SignOut"
                        className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:outline-none "
                      >
                        Sign out
                      </Link>
                    </MenuItem>
                  </MenuItems>
                </Menu>
              </div>
            ) : (
              <div className="flex-shrink-0">
                <Link
                  to="SignIn"
                  className="relative inline-flex items-center outline-regal-beige outline outline-2 rounded-md bg-regal-blue px-3 py-2 text-sm font-semibold text-regal-beige shadow-sm"
                >
                  Sign In
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </Disclosure>
  );
}
