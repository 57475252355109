import { useEffect, useState } from "react";
import { db, auth } from "../firebaseConfig";
import { onSnapshot, doc, collection } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

export default function Payment() {
  const [userCredits, setUserCredits] = useState(0);
  const [userId, setuserId] = useState("test");

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(collection(db, "users"), userId),
      (doc) => {
        const curData = doc.data();
        if (curData) {
          setUserCredits(curData.credits);
        }
      }
    );

    return () => unsubscribe();
  }, [userId]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setuserId(user.uid);
      } else {
        setuserId("test");
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      <div className="bg-regal-blue">
        <h1 className="bg-regal-blue text-regal-beige text-lg p-4 text-center">
          You're out of songs, buy more to make your study song
        </h1>
        <h2 className="text-regal-beige text-lg text-center mb-4">
          Your credits: {userCredits}
        </h2>
        <div className="flex justify-center items-center h-screen bg-regal-blue">
          <iframe
            src="https://www.buymeacoffee.com/texttotracks/extras"
            title="BMC Extras"
            className="w-4/5 h-5/6 border-none"
          />
        </div>
      </div>
    </>
  );
}
