import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db, auth } from "../firebaseConfig"; // adjust the import path as needed
import { onAuthStateChanged } from "firebase/auth";
import SongItem from "./SongItem";

export default function SongsList() {
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSongs = async (userEmail) => {
      try {
        const q = query(
          collection(db, "songs"),
          where("email", "==", userEmail)
        );
        const querySnapshot = await getDocs(q);
        const songsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSongs(songsData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchSongs(user.email);
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (songs.length === 0) {
    return <div>No songs found.</div>;
  }

  return (
    <div>
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl/7 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Your Songs
          </h2>
        </div>
      </div>
      <ul className="divide-y divide-gray-100">
        {songs.map((song) => (
          <SongItem key={song.id} song={song} />
        ))}
      </ul>
    </div>
  );
}
