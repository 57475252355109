// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStripePayments } from "@invertase/firestore-stripe-payments";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyARkaNkcnZGUP9gXjmMXLgJGKVHjjNsnDI",
  authDomain: "lern-lied-fb.firebaseapp.com",
  projectId: "lern-lied-fb",
  storageBucket: "lern-lied-fb.appspot.com",
  messagingSenderId: "944405204640",
  appId: "1:944405204640:web:18cb56bafe4b8090b883c5",
  measurementId: "G-CLE8PPVLJ2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth(app);

const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "customers",
});

export { app, db, auth, payments };
