import { Link } from "react-router-dom";

export default function Success() {
  return (
    <div className="bg-regal-blue min-h-screen">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-4xl font-semibold tracking-tight text-regal-beige sm:text-5xl">
            Your Song Request has been made, we'll send tunes your way!
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg text-regal-beige">
            Create an account to download your custom mp3 file and start
            learning with rhythm.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link to="../createAccount">
              <button className="rounded-md bg-regal-beige px-3.5 py-2.5 text-sm font-semibold text-regal-blue shadow-sm hover:bg-regal-beige/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-regal-beige">
                Create Account
              </button>
            </Link>
            <Link to="../about">
              <button className="text-sm font-semibold leading-6 text-regal-beige">
                Learn more <span aria-hidden="true">→</span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
