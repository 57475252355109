import { useState } from "react";

const useMultiStepForm = (steps) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const next = () => {
    setCurrentStepIndex((i) => i + 1);
  };

  const back = () => {
    setCurrentStepIndex((i) => i - 1);
  };

  const goTo = (index) => {
    setCurrentStepIndex(index);
  };

  const currentStep = steps[currentStepIndex];

  return {
    currentStep,
    currentStepIndex,
    next,
    back,
    goTo,
  };
};

export default useMultiStepForm;
