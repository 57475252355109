import { db } from "../firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import incrementUserCredits from "./incrementCredits";
import { songCreditCost } from "./magicNumbers";
import { validateUserEmailForSong } from "./validateUserEmailForSong";
import axios from "axios";

export const uploadScript = async (info) => {
  const modified_script = await split_AI(info.script);
  console.log(modified_script);

  const chunks = splitScript(modified_script);
  let index = 1;

  for (const scriptChunk of chunks) {
    // only if enough credits are there
    if (await validateUserEmailForSong(info.email)) {
      const serverTime = Timestamp.now();
      console.log(serverTime);
      const chunkInfo = {
        createdAt: serverTime,
        filled: false,
        lyrics: scriptChunk,
        title: `${info.name}_song${index}`,
        email: info.email,
        script: info.name,
        valid: true,
      };
      console.log(`${info.name}_song${index}`);

      const collectionRef = collection(db, `songRequests`);
      await addDoc(collectionRef, chunkInfo); // Await the addDoc operation
      await incrementUserCredits(info.email, -songCreditCost); // Await the incrementUserCredits operation
      index += 1;
    }
  }
  return index;
};

function splitScript(script, maxChunkSize = 3000) {
  if (typeof script !== "string") {
    throw new Error("Input must be a string");
  }

  const sentenceEndPattern = /(\.|\?|!)\s+/g;
  let sentences = script.split(sentenceEndPattern).filter(Boolean);
  let result = [];
  let currentChunk = "";

  for (let i = 0; i < sentences.length; i += 2) {
    let sentence = sentences[i] + (sentences[i + 1] || "");

    if ((currentChunk + sentence).length > maxChunkSize) {
      result.push(currentChunk.trim());
      currentChunk = sentence;
    } else {
      currentChunk += sentence;
    }
  }

  if (currentChunk) {
    result.push(currentChunk.trim());
  }

  return result;
}

async function split_AI(script) {
  console.log(script);
  const prompt =
    "make this have similar sentence lengths and catchier to be suitable for a song, but don't shorten the script";
  const requestBody = {
    message: script,
    prompt: prompt,
  };

  try {
    const res = await axios.post(
      "https://llamachat-y4zrrpqboq-uc.a.run.app",
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res.data.response;
  } catch (error) {
    console.error("Error:", error);
  }
}
