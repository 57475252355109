import React, { useEffect, useState, useRef } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

export default function SongItem({ song }) {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const audio = audioRef.current;

    const updateCurrentTime = () => setCurrentTime(audio.currentTime);
    const updateDuration = () => setDuration(audio.duration);

    audio.addEventListener("timeupdate", updateCurrentTime);
    audio.addEventListener("loadedmetadata", updateDuration);

    return () => {
      audio.removeEventListener("timeupdate", updateCurrentTime);
      audio.removeEventListener("loadedmetadata", updateDuration);
    };
  }, []);

  const handlePlayPause = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleSeek = (event) => {
    const seekTime = (event.target.value / 100) * duration;
    audioRef.current.currentTime = seekTime;
    setCurrentTime(seekTime);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Generate file name from song title (e.g. "Untitled Song.mp3" if no title exists)
  const fileName = song.title ? `${song.title}.mp3` : "Untitled Song.mp3";

  const handleDownload = async () => {
    const response = await fetch(`https://cdn1.suno.ai/${song.id}.mp3`);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName; // Set the filename
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url); // Cleanup the URL object
  };

  return (
    <li className="flex items-center justify-between gap-x-6 py-5">
      <div className="min-w-0 flex-1">
        <div className="flex items-start gap-x-3">
          <p className="text-sm/6 font-semibold text-gray-900">
            {song.title ? song.title : "Untitled Song"}
          </p>
        </div>
        <div className="mt-1 flex items-center gap-x-2 text-xs/5 text-gray-500">
          <p className="whitespace-nowrap">
            Created on <time dateTime={song.created_at}>{song.created_at}</time>
          </p>
          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
            <circle r={1} cx={1} cy={1} />
          </svg>
          <p className="truncate">Created by you</p>
        </div>
      </div>
      <div className="flex items-center flex-1 space-x-4">
        <div className="flex items-center flex-1 space-x-2">
          <input
            type="range"
            value={(currentTime / duration) * 100 || 0}
            onChange={handleSeek}
            className="w-full"
          />
          <div className="text-xs/5 text-gray-500">
            {formatTime(currentTime)} / {formatTime(duration)}
          </div>
        </div>
        <button
          onClick={handlePlayPause}
          className="rounded-md bg-blue-500 px-2.5 py-1.5 text-sm font-semibold text-white hover:bg-blue-600"
        >
          {isPlaying ? "Pause" : "Play"}
        </button>
      </div>
      <div className="flex flex-none items-center gap-x-4">
        <Menu as="div" className="relative flex-none">
          <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
          </MenuButton>
          <MenuItems
            transition
            className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
          >
            <MenuItem>
              <button
                onClick={handleDownload} // Trigger download on button click
                className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
              >
                Download<span className="sr-only">, {song.title}</span>
              </button>
            </MenuItem>
            <MenuItem>
              <Link
                href="#"
                className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
              >
                Delete<span className="sr-only">, {song.title}</span>
              </Link>
            </MenuItem>
          </MenuItems>
        </Menu>
      </div>
      <audio ref={audioRef} src={`https://cdn1.suno.ai/${song.id}.mp3`} />
    </li>
  );
}
