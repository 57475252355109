import React, { useState, useEffect } from "react";
import {
  moodOptions,
  tempoOptions,
  voiceOptions,
} from "../Services/magicNumbers";
import { genreOptions } from "../Services/magicNumbers";

const GenreSelection = ({ genres, setGenres, setStep, currentStep }) => {
  const toggleGenre = (event, genre) => {
    event.preventDefault(); // prevent form submit
    setGenres((prevGenres) =>
      prevGenres.includes(genre)
        ? prevGenres.filter((g) => g !== genre)
        : [...prevGenres, genre]
    );
  };

  // Automatically move to the next step once 2 genres are selected
  useEffect(() => {
    if (genres.length === 2 && currentStep === 2) {
      setStep(3); // Go to BPM step after 2 genres
    }
  }, [genres, setStep, currentStep]); // Ensure setStep is a dependency here

  return (
    <div>
      <p className="text-lg font-semibold text-regal-beige mb-5">
        Select Your Favorite Genres (2)
      </p>
      <div className="flex flex-wrap justify-center gap-4">
        {genreOptions.map((genre) => (
          <button
            type="button"
            key={genre}
            className={`rounded-md outline-regal-beige outline outline-2 px-3 py-2 text-sm font-semibold shadow-sm hover:bg-regal-beige hover:text-black  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-focus-ring-color ${
              genres.includes(genre)
                ? "bg-regal-beige text-black" // Selected state
                : "bg-regal-blue text-regal-beige"
            } hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-focus-ring-color`}
            onClick={(e) => toggleGenre(e, genre)}
          >
            {genre}
          </button>
        ))}
      </div>
    </div>
  );
};

const CustomizeSongStyle = ({ onValidationChange }) => {
  const [step, setStep] = useState(1); // Define step state here
  const [genres, setGenres] = useState([]);
  const [bpm, setBpm] = useState("");
  const [vocalStyle, setVocalStyle] = useState("");
  const [mood, setMood] = useState("");
  const [isValid, setIsValid] = useState(false);

  const handleBpmChange = (bpm) => {
    setBpm(bpm);
  };

  const handleVocalStyleChange = (style) => {
    setVocalStyle(style);
  };

  const handleMoodChange = (mood) => {
    setMood(mood);
  };

  // Automatically move to the next step once BPM is selected
  useEffect(() => {
    if (bpm) {
      setStep(4); // Move to Vocal Style step
    }
  }, [bpm]);

  // Automatically move to the next step once Vocal Style is selected
  useEffect(() => {
    if (vocalStyle) {
      setStep(5); // Move to Mood step
    }
  }, [vocalStyle]);

  // Automatically move to the next step once Mood is selected
  useEffect(() => {
    if (mood) {
      setIsValid(true);
    }
  }, [mood]);

  useEffect(() => {
    if (isValid) {
      onValidationChange(isValid); // Notify parent when isValid changes
    }
  }, [isValid, onValidationChange]);

  return (
    <div className="w-full mx-auto  bg-regal-blue rounded-lg">
      {/* Step 1: Initial Button */}
      {step === 1 && (
        <button
          type="button"
          onClick={() => setStep(2)} // Clicking this button moves to Step 2
          className="rounded-md w-full bg-regal-blue outline-regal-beige outline outline-2 px-3 py-2 text-sm font-semibold text-regal-beige shadow-sm hover:bg-regal-beige hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-focus-ring-color mt-5"
        >
          create your music style
        </button>
      )}

      {/* Step 2: Genre Selection */}
      {step === 2 && (
        <div>
          <GenreSelection
            genres={genres}
            setGenres={setGenres}
            setStep={setStep}
            currentStep={step}
          />
          <div className="flex justify-center items-center  mt-4">
            {/* Back button as a square with an arrow */}
            <button
              type="button"
              onClick={() => setStep(1)} // Go back to Step 1
              className="w-10 h-10 flex items-center justify-center bg-regal-blue text-regal-beige rounded-full hover:bg-blue-400 transition duration-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
          </div>
        </div>
      )}

      {/* Step 3: BPM Selection */}
      {step === 3 && (
        <div>
          <p className="text-lg font-semibold text-regal-beige mb-5">
            Select BPM
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            {tempoOptions.map((bpmOption) => (
              <button
                type="button"
                key={bpmOption}
                onClick={() => handleBpmChange(bpmOption)}
                className={`rounded-md outline-regal-beige outline outline-2 px-3 py-2 text-sm font-semibold shadow-sm hover:bg-regal-beige hover:text-black  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-focus-ring-color  ${
                  bpm === bpmOption
                    ? "bg-regal-beige text-black" // Selected state
                    : "bg-regal-blue text-regal-beige"
                }`}
              >
                {bpmOption} BPM
              </button>
            ))}
          </div>
          <div className="flex justify-center items-center  mt-4">
            {/* Back button as a square with an arrow */}
            <button
              type="button"
              onClick={() => {
                setStep(2);
                setGenres([]);
              }} // Go back to Step 2 (Genres)
              className="w-10 h-10 flex items-center justify-center bg-regal-blue text-regal-beige rounded-full hover:bg-blue-400 transition duration-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
          </div>
        </div>
      )}

      {/* Step 4: Vocal Style Selection */}
      {step === 4 && (
        <div>
          <p className="text-lg font-semibold text-regal-beige mb-5">
            Select Vocal Style
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            {voiceOptions.map((style) => (
              <button
                type="button"
                key={style}
                onClick={() => handleVocalStyleChange(style)}
                className={`rounded-md outline-regal-beige outline outline-2 px-3 py-2 text-sm font-semibold  shadow-sm hover:bg-regal-beige hover:text-black  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-focus-ring-color  ${
                  vocalStyle === style
                    ? "bg-regal-beige text-black" // Selected state
                    : "bg-regal-blue text-regal-beige"
                }`}
              >
                {style}
              </button>
            ))}
          </div>
          <div className="flex justify-center items-center  mt-4">
            {/* Back button as a square with an arrow */}
            <button
              type="button"
              onClick={() => setStep(3)} // Go back to Step 3 (BPM)
              className="w-10 h-10 flex items-center justify-center bg-regal-blue text-regal-beige rounded-full hover:bg-blue-400 transition duration-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
          </div>
        </div>
      )}

      {/* Step 5: Mood Selection */}
      {step === 5 && (
        <div>
          <p className="text-lg font-semibold text-regal-beige mb-5">
            Select Mood (1-2)
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            {moodOptions.map((moodOption) => (
              <button
                type="button"
                key={moodOption}
                onClick={() => handleMoodChange(moodOption)}
                className={`rounded-md outline-regal-beige outline outline-2 px-3 py-2 text-sm font-semibold  shadow-sm hover:bg-regal-beige hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-focus-ring-color  ${
                  mood === moodOption
                    ? "bg-regal-beige text-black" // Selected state
                    : "bg-regal-blue text-regal-beige"
                }`}
              >
                {moodOption}
              </button>
            ))}
          </div>
          <div className="flex justify-center items-center  mt-4">
            {/* Back button as a square with an arrow */}
            <button
              type="button"
              onClick={() => setStep(4)} // Go back to Step 4 (Vocal Style)
              className="w-10 h-10 flex items-center justify-center bg-regal-blue text-regal-beige rounded-full hover:bg-blue-400 transition duration-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomizeSongStyle;
